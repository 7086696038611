.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

.colorChange {
	background: yellow;
}

.red{
	color: red;
}

/* .navigation .nav-item > li > a:hover {
  background: #f0f0f0;
} */
.navigation .nav-item .active {
	font-weight: 700;
	color: #55595c !important;
	border-right: 4px solid #3bafda !important;
	background: #f0f0f0 !important;
}